@layer theme {
  :root, :host {
    --font-sans: ui-sans-serif, system-ui, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
    --font-mono: ui-monospace, SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
    --color-red-500: #fb2c36;
    --color-green-500: #00c758;
    --color-blue-500: #3080ff;
    --color-gray-200: #e5e7eb;
    --color-gray-800: #1e2939;
    --color-black: #000;
    --color-white: #fff;
    --spacing: .25rem;
    --container-sm: 24rem;
    --container-lg: 32rem;
    --container-xl: 36rem;
    --container-2xl: 42rem;
    --container-4xl: 56rem;
    --text-xs: .75rem;
    --text-xs--line-height: calc(1 / .75);
    --text-sm: .875rem;
    --text-sm--line-height: calc(1.25 / .875);
    --text-base: 1rem;
    --text-base--line-height: calc(1.5 / 1);
    --text-lg: 1.125rem;
    --text-lg--line-height: calc(1.75 / 1.125);
    --text-xl: 1.25rem;
    --text-xl--line-height: calc(1.75 / 1.25);
    --text-2xl: 1.5rem;
    --text-2xl--line-height: calc(2 / 1.5);
    --text-3xl: 1.875rem;
    --text-3xl--line-height: calc(2.25 / 1.875);
    --font-weight-normal: 400;
    --font-weight-medium: 500;
    --font-weight-semibold: 600;
    --font-weight-bold: 700;
    --tracking-tight: -.025em;
    --tracking-wide: .025em;
    --tracking-widest: .1em;
    --leading-tight: 1.25;
    --radius-sm: calc(var(--radius)  - 4px);
    --radius-md: calc(var(--radius)  - 2px);
    --radius-lg: var(--radius);
    --radius-xl: .75rem;
    --ease-in-out: cubic-bezier(.4, 0, .2, 1);
    --animate-spin: spin 1s linear infinite;
    --animate-pulse: pulse 2s cubic-bezier(.4, 0, .6, 1) infinite;
    --aspect-video: 16 / 9;
    --default-transition-duration: .15s;
    --default-transition-timing-function: cubic-bezier(.4, 0, .2, 1);
    --default-font-family: var(--font-sans);
    --default-mono-font-family: var(--font-mono);
    --color-border: hsl(var(--border));
    --color-input: hsl(var(--input));
    --color-ring: hsl(var(--ring));
    --color-background: hsl(var(--background));
    --color-foreground: hsl(var(--foreground));
    --color-primary: hsl(var(--primary));
    --color-primary-foreground: hsl(var(--primary-foreground));
    --color-secondary: hsl(var(--secondary));
    --color-secondary-foreground: hsl(var(--secondary-foreground));
    --color-destructive: hsl(var(--destructive));
    --color-destructive-foreground: hsl(var(--destructive-foreground));
    --color-muted: hsl(var(--muted));
    --color-muted-foreground: hsl(var(--muted-foreground));
    --color-accent: hsl(var(--accent));
    --color-accent-foreground: hsl(var(--accent-foreground));
    --color-popover: hsl(var(--popover));
    --color-popover-foreground: hsl(var(--popover-foreground));
    --color-card: hsl(var(--card));
    --color-card-foreground: hsl(var(--card-foreground));
    --color-sidebar: hsl(var(--sidebar-background));
    --color-sidebar-foreground: hsl(var(--sidebar-foreground));
    --color-sidebar-primary: hsl(var(--sidebar-primary));
    --color-sidebar-primary-foreground: hsl(var(--sidebar-primary-foreground));
    --color-sidebar-accent: hsl(var(--sidebar-accent));
    --color-sidebar-accent-foreground: hsl(var(--sidebar-accent-foreground));
    --color-sidebar-border: hsl(var(--sidebar-border));
    --color-sidebar-ring: hsl(var(--sidebar-ring));
    --animate-caret-blink: caret-blink 1.25s ease-out infinite;
  }

  @supports (color: color(display-p3 0 0 0)) {
    :root, :host {
      --color-red-500: color(display-p3 .903738 .262579 .253307);
      --color-green-500: color(display-p3 .308734 .774754 .374307);
      --color-blue-500: color(display-p3 .266422 .491219 .988624);
      --color-gray-200: color(display-p3 .899787 .906171 .92106);
      --color-gray-800: color(display-p3 .125854 .159497 .216835);
    }
  }

  @supports (color: lab(0% 0 0)) {
    :root, :host {
      --color-red-500: lab(55.4814% 75.0732 48.8528);
      --color-green-500: lab(70.5521% -66.5147 45.8073);
      --color-blue-500: lab(54.1736% 13.3369 -74.6839);
      --color-gray-200: lab(91.6229% -.159115 -2.26791);
      --color-gray-800: lab(16.1051% -1.18239 -11.7533);
    }
  }
}

@layer base {
  *, :after, :before {
    box-sizing: border-box;
    border: 0 solid;
    margin: 0;
    padding: 0;
  }

  ::backdrop {
    box-sizing: border-box;
    border: 0 solid;
    margin: 0;
    padding: 0;
  }

  ::-webkit-file-upload-button {
    box-sizing: border-box;
    border: 0 solid;
    margin: 0;
    padding: 0;
  }

  ::file-selector-button {
    box-sizing: border-box;
    border: 0 solid;
    margin: 0;
    padding: 0;
  }

  html, :host {
    -webkit-text-size-adjust: 100%;
    -moz-tab-size: 4;
    tab-size: 4;
    line-height: 1.5;
    font-family: var(--default-font-family, ui-sans-serif, system-ui, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji");
    font-feature-settings: var(--default-font-feature-settings, normal);
    font-variation-settings: var(--default-font-variation-settings, normal);
    -webkit-tap-highlight-color: transparent;
  }

  hr {
    height: 0;
    color: inherit;
    border-top-width: 1px;
  }

  abbr:where([title]) {
    -webkit-text-decoration: underline dotted;
    text-decoration: underline dotted;
  }

  h1, h2, h3, h4, h5, h6 {
    font-size: inherit;
    font-weight: inherit;
  }

  a {
    color: inherit;
    -webkit-text-decoration: inherit;
    -webkit-text-decoration: inherit;
    -webkit-text-decoration: inherit;
    -webkit-text-decoration: inherit;
    text-decoration: inherit;
  }

  b, strong {
    font-weight: bolder;
  }

  code, kbd, samp, pre {
    font-family: var(--default-mono-font-family, ui-monospace, SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace);
    font-feature-settings: var(--default-mono-font-feature-settings, normal);
    font-variation-settings: var(--default-mono-font-variation-settings, normal);
    font-size: 1em;
  }

  small {
    font-size: 80%;
  }

  sub, sup {
    vertical-align: baseline;
    font-size: 75%;
    line-height: 0;
    position: relative;
  }

  sub {
    bottom: -.25em;
  }

  sup {
    top: -.5em;
  }

  table {
    text-indent: 0;
    border-color: inherit;
    border-collapse: collapse;
  }

  :-moz-focusring {
    outline: auto;
  }

  progress {
    vertical-align: baseline;
  }

  summary {
    display: list-item;
  }

  ol, ul, menu {
    list-style: none;
  }

  img, svg, video, canvas, audio, iframe, embed, object {
    vertical-align: middle;
    display: block;
  }

  img, video {
    max-width: 100%;
    height: auto;
  }

  button, input, select, optgroup, textarea {
    font: inherit;
    font-feature-settings: inherit;
    font-variation-settings: inherit;
    letter-spacing: inherit;
    color: inherit;
    opacity: 1;
    background-color: #0000;
    border-radius: 0;
  }

  ::-webkit-file-upload-button {
    font: inherit;
    font-feature-settings: inherit;
    font-variation-settings: inherit;
    letter-spacing: inherit;
    color: inherit;
    opacity: 1;
    background-color: #0000;
    border-radius: 0;
  }

  ::file-selector-button {
    font: inherit;
    font-feature-settings: inherit;
    font-variation-settings: inherit;
    letter-spacing: inherit;
    color: inherit;
    opacity: 1;
    background-color: #0000;
    border-radius: 0;
  }

  :where(select:-webkit-any([multiple], [size])) optgroup {
    font-weight: bolder;
  }

  :where(select:-moz-any([multiple], [size])) optgroup {
    font-weight: bolder;
  }

  :where(select:is([multiple], [size])) optgroup {
    font-weight: bolder;
  }

  :where(select:-webkit-any([multiple], [size])) optgroup option {
    padding-inline-start: 20px;
  }

  :where(select:-moz-any([multiple], [size])) optgroup option {
    padding-inline-start: 20px;
  }

  :where(select:is([multiple], [size])) optgroup option {
    padding-inline-start: 20px;
  }

  ::-webkit-file-upload-button {
    margin-inline-end: 4px;
  }

  ::file-selector-button {
    margin-inline-end: 4px;
  }

  ::placeholder {
    opacity: 1;
  }

  @supports (not ((-webkit-appearance: -apple-pay-button))) or (contain-intrinsic-size: 1px) {
    ::placeholder {
      color: color-mix(in oklab, currentColor 50%, transparent);
    }
  }

  textarea {
    resize: vertical;
  }

  ::-webkit-search-decoration {
    -webkit-appearance: none;
  }

  ::-webkit-date-and-time-value {
    min-height: 1lh;
    text-align: inherit;
  }

  ::-webkit-datetime-edit {
    display: inline-flex;
  }

  ::-webkit-datetime-edit-fields-wrapper {
    padding: 0;
  }

  ::-webkit-datetime-edit {
    padding-block-start: 0;
    padding-block-end: 0;
  }

  ::-webkit-datetime-edit-year-field {
    padding-block-start: 0;
    padding-block-end: 0;
  }

  ::-webkit-datetime-edit-month-field {
    padding-block-start: 0;
    padding-block-end: 0;
  }

  ::-webkit-datetime-edit-day-field {
    padding-block-start: 0;
    padding-block-end: 0;
  }

  ::-webkit-datetime-edit-hour-field {
    padding-block-start: 0;
    padding-block-end: 0;
  }

  ::-webkit-datetime-edit-minute-field {
    padding-block-start: 0;
    padding-block-end: 0;
  }

  ::-webkit-datetime-edit-second-field {
    padding-block-start: 0;
    padding-block-end: 0;
  }

  ::-webkit-datetime-edit-millisecond-field {
    padding-block-start: 0;
    padding-block-end: 0;
  }

  ::-webkit-datetime-edit-meridiem-field {
    padding-block-start: 0;
    padding-block-end: 0;
  }

  :-moz-ui-invalid {
    box-shadow: none;
  }

  button {
    -webkit-appearance: button;
    -moz-appearance: button;
    appearance: button;
  }

  input:where([type="button"], [type="reset"], [type="submit"]) {
    -webkit-appearance: button;
    -moz-appearance: button;
    appearance: button;
  }

  ::-webkit-file-upload-button {
    -webkit-appearance: button;
    -moz-appearance: button;
    appearance: button;
  }

  ::file-selector-button {
    -webkit-appearance: button;
    -moz-appearance: button;
    appearance: button;
  }

  ::-webkit-inner-spin-button {
    height: auto;
  }

  ::-webkit-outer-spin-button {
    height: auto;
  }

  [hidden]:where(:not([hidden="until-found"])) {
    display: none !important;
  }

  *, :after, :before {
    border-color: var(--color-gray-200, currentColor);
  }

  ::backdrop {
    border-color: var(--color-gray-200, currentColor);
  }

  ::-webkit-file-upload-button {
    border-color: var(--color-gray-200, currentColor);
  }

  ::file-selector-button {
    border-color: var(--color-gray-200, currentColor);
  }

  button:not(:disabled), [role="button"]:not(:disabled) {
    cursor: pointer;
  }

  :root {
    --background: 0 0% 100%;
    --foreground: 240 10% 3.9%;
    --card: 0 0% 100%;
    --card-foreground: 240 10% 3.9%;
    --popover: 0 0% 100%;
    --popover-foreground: 240 10% 3.9%;
    --primary: 240 5.9% 10%;
    --primary-foreground: 0 0% 98%;
    --secondary: 240 4.8% 95.9%;
    --secondary-foreground: 240 5.9% 10%;
    --muted: 240 4.8% 95.9%;
    --muted-foreground: 240 3.8% 46.1%;
    --accent: 240 4.8% 95.9%;
    --accent-foreground: 240 5.9% 10%;
    --destructive: 0 84.2% 60.2%;
    --destructive-foreground: 0 0% 98%;
    --border: 240 5.9% 90%;
    --input: 240 5.9% 90%;
    --ring: 240 10% 3.9%;
    --radius: .5rem;
    --chart-1: 12 76% 61%;
    --chart-2: 173 58% 39%;
    --chart-3: 197 37% 24%;
    --chart-4: 43 74% 66%;
    --chart-5: 27 87% 67%;
    --sidebar-background: 0 0% 98%;
    --sidebar-foreground: 240 5.3% 26.1%;
    --sidebar-primary: 240 5.9% 10%;
    --sidebar-primary-foreground: 0 0% 98%;
    --sidebar-accent: 240 4.8% 95.9%;
    --sidebar-accent-foreground: 240 5.9% 10%;
    --sidebar-border: 220 13% 91%;
    --sidebar-ring: 217.2 91.2% 59.8%;
  }

  :root[class~="dark"] {
    --background: 240 10% 3.9%;
    --foreground: 0 0% 98%;
    --card: 240 10% 3.9%;
    --card-foreground: 0 0% 98%;
    --popover: 240 10% 3.9%;
    --popover-foreground: 0 0% 98%;
    --primary: 0 0% 98%;
    --primary-foreground: 240 5.9% 10%;
    --secondary: 240 3.7% 15.9%;
    --secondary-foreground: 0 0% 98%;
    --muted: 240 3.7% 15.9%;
    --muted-foreground: 240 5% 64.9%;
    --accent: 240 3.7% 15.9%;
    --accent-foreground: 0 0% 98%;
    --destructive: 0 62.8% 30.6%;
    --destructive-foreground: 0 0% 98%;
    --border: 240 3.7% 15.9%;
    --input: 240 3.7% 15.9%;
    --ring: 240 4.9% 83.9%;
    --chart-1: 220 70% 50%;
    --chart-2: 160 60% 45%;
    --chart-3: 30 80% 55%;
    --chart-4: 280 65% 60%;
    --chart-5: 340 75% 55%;
  }

  .dark {
    --sidebar-background: 240 5.9% 10%;
    --sidebar-foreground: 240 4.8% 95.9%;
    --sidebar-primary: 224.3 76.3% 48%;
    --sidebar-primary-foreground: 0 0% 100%;
    --sidebar-accent: 240 3.7% 15.9%;
    --sidebar-accent-foreground: 240 4.8% 95.9%;
    --sidebar-border: 240 3.7% 15.9%;
    --sidebar-ring: 217.2 91.2% 59.8%;
  }

  html, body {
    width: 100dvw;
    height: 100dvh;
    max-height: 100dvh;
    font-family: var(--font-sans);
  }

  body {
    cursor: default;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    -webkit-user-select: none;
    user-select: none;
    overflow: hidden;
  }

  * {
    -webkit-user-drag: none;
    border-color: var(--color-border);
  }

  body {
    background-color: var(--color-background);
    color: var(--color-foreground);
  }
}

@layer components;

@layer utilities {
  .pointer-events-none {
    pointer-events: none;
  }

  .invisible {
    visibility: hidden;
  }

  .visible {
    visibility: visible;
  }

  .sr-only {
    clip: rect(0, 0, 0, 0);
    white-space: nowrap;
    border-width: 0;
    width: 1px;
    height: 1px;
    margin: -1px;
    padding: 0;
    position: absolute;
    overflow: hidden;
  }

  .absolute {
    position: absolute;
  }

  .fixed {
    position: fixed;
  }

  .relative {
    position: relative;
  }

  .static {
    position: static;
  }

  .inset-0 {
    inset: calc(var(--spacing) * 0);
  }

  .inset-x-0 {
    inset-inline: calc(var(--spacing) * 0);
  }

  .inset-y-0 {
    inset-block: calc(var(--spacing) * 0);
  }

  .top-0 {
    top: calc(var(--spacing) * 0);
  }

  .top-1\.5 {
    top: calc(var(--spacing) * 1.5);
  }

  .top-3\.5 {
    top: calc(var(--spacing) * 3.5);
  }

  .top-4 {
    top: calc(var(--spacing) * 4);
  }

  .top-\[50\%\] {
    top: 50%;
  }

  .right-0 {
    right: calc(var(--spacing) * 0);
  }

  .right-1 {
    right: calc(var(--spacing) * 1);
  }

  .right-3 {
    right: calc(var(--spacing) * 3);
  }

  .right-4 {
    right: calc(var(--spacing) * 4);
  }

  .bottom-0 {
    bottom: calc(var(--spacing) * 0);
  }

  .bottom-1 {
    bottom: calc(var(--spacing) * 1);
  }

  .left-0 {
    left: calc(var(--spacing) * 0);
  }

  .left-1 {
    left: calc(var(--spacing) * 1);
  }

  .left-2 {
    left: calc(var(--spacing) * 2);
  }

  .left-\[50\%\] {
    left: 50%;
  }

  .z-10 {
    z-index: 10;
  }

  .z-20 {
    z-index: 20;
  }

  .z-30 {
    z-index: 30;
  }

  .z-50 {
    z-index: 50;
  }

  .m-auto {
    margin: auto;
  }

  .-mx-1 {
    margin-inline: calc(var(--spacing) * -1);
  }

  .mx-1 {
    margin-inline: calc(var(--spacing) * 1);
  }

  .mx-2 {
    margin-inline: calc(var(--spacing) * 2);
  }

  .mx-3\.5 {
    margin-inline: calc(var(--spacing) * 3.5);
  }

  .mx-auto {
    margin-inline-start: auto;
    margin-inline-end: auto;
  }

  .my-0\.5 {
    margin-block: calc(var(--spacing) * .5);
  }

  .my-1 {
    margin-block: calc(var(--spacing) * 1);
  }

  .my-auto {
    margin-block-start: auto;
    margin-block-end: auto;
  }

  .mt-2 {
    margin-top: calc(var(--spacing) * 2);
  }

  .mt-4 {
    margin-top: calc(var(--spacing) * 4);
  }

  .mt-24 {
    margin-top: calc(var(--spacing) * 24);
  }

  .mt-auto {
    margin-top: auto;
  }

  .mr-1 {
    margin-right: calc(var(--spacing) * 1);
  }

  .mr-2 {
    margin-right: calc(var(--spacing) * 2);
  }

  .mb-1 {
    margin-bottom: calc(var(--spacing) * 1);
  }

  .-ml-1 {
    margin-left: calc(var(--spacing) * -1);
  }

  .ml-2 {
    margin-left: calc(var(--spacing) * 2);
  }

  .ml-auto {
    margin-left: auto;
  }

  .block {
    display: block;
  }

  .contents {
    display: contents;
  }

  .flex {
    display: flex;
  }

  .grid {
    display: grid;
  }

  .hidden {
    display: none;
  }

  .inline-flex {
    display: inline-flex;
  }

  .table {
    display: table;
  }

  .aspect-square {
    aspect-ratio: 1;
  }

  .aspect-video {
    aspect-ratio: var(--aspect-video);
  }

  .size-4 {
    width: calc(var(--spacing) * 4);
    height: calc(var(--spacing) * 4);
  }

  .size-5 {
    width: calc(var(--spacing) * 5);
    height: calc(var(--spacing) * 5);
  }

  .size-6 {
    width: calc(var(--spacing) * 6);
    height: calc(var(--spacing) * 6);
  }

  .size-7 {
    width: calc(var(--spacing) * 7);
    height: calc(var(--spacing) * 7);
  }

  .size-8 {
    width: calc(var(--spacing) * 8);
    height: calc(var(--spacing) * 8);
  }

  .size-10 {
    width: calc(var(--spacing) * 10);
    height: calc(var(--spacing) * 10);
  }

  .size-12 {
    width: calc(var(--spacing) * 12);
    height: calc(var(--spacing) * 12);
  }

  .size-fit {
    width: -moz-fit-content;
    width: fit-content;
    height: -moz-fit-content;
    height: fit-content;
  }

  .size-full {
    width: 100%;
    height: 100%;
  }

  .h-2 {
    height: calc(var(--spacing) * 2);
  }

  .h-2\.5 {
    height: calc(var(--spacing) * 2.5);
  }

  .h-3\.5 {
    height: calc(var(--spacing) * 3.5);
  }

  .h-4 {
    height: calc(var(--spacing) * 4);
  }

  .h-5 {
    height: calc(var(--spacing) * 5);
  }

  .h-6 {
    height: calc(var(--spacing) * 6);
  }

  .h-7 {
    height: calc(var(--spacing) * 7);
  }

  .h-8 {
    height: calc(var(--spacing) * 8);
  }

  .h-9 {
    height: calc(var(--spacing) * 9);
  }

  .h-10 {
    height: calc(var(--spacing) * 10);
  }

  .h-11 {
    height: calc(var(--spacing) * 11);
  }

  .h-12 {
    height: calc(var(--spacing) * 12);
  }

  .h-16 {
    height: calc(var(--spacing) * 16);
  }

  .h-24 {
    height: calc(var(--spacing) * 24);
  }

  .h-32 {
    height: calc(var(--spacing) * 32);
  }

  .h-\[1px\] {
    height: 1px;
  }

  .h-\[calc\(75vh-8rem\)\] {
    height: calc(75vh - 8rem);
  }

  .h-\[calc\(100dvh-3rem\)\] {
    height: calc(100dvh - 3rem);
  }

  .h-\[calc\(100svh-4rem\)\] {
    height: calc(100svh - 4rem);
  }

  .h-\[calc\(100svh-4rem-4rem\)\] {
    height: calc(100svh - 8rem);
  }

  .h-\[var\(--radix-select-trigger-height\)\] {
    height: var(--radix-select-trigger-height);
  }

  .h-auto {
    height: auto;
  }

  .h-dvh {
    height: 100dvh;
  }

  .h-full {
    height: 100%;
  }

  .h-px {
    height: 1px;
  }

  .h-svh {
    height: 100svh;
  }

  .max-h-96 {
    max-height: calc(var(--spacing) * 96);
  }

  .max-h-\[250px\] {
    max-height: 250px;
  }

  .max-h-\[300px\] {
    max-height: 300px;
  }

  .max-h-fit {
    max-height: -moz-fit-content;
    max-height: fit-content;
  }

  .min-h-0 {
    min-height: calc(var(--spacing) * 0);
  }

  .min-h-\[80px\] {
    min-height: 80px;
  }

  .min-h-\[calc\(75vh-8rem\)\] {
    min-height: calc(75vh - 8rem);
  }

  .min-h-\[calc\(100dvh-3rem\)\] {
    min-height: calc(100dvh - 3rem);
  }

  .min-h-\[calc\(100svh-4rem\)\] {
    min-height: calc(100svh - 4rem);
  }

  .min-h-\[calc\(100svh-4rem-4rem\)\] {
    min-height: calc(100svh - 8rem);
  }

  .min-h-dvh {
    min-height: 100dvh;
  }

  .min-h-svh {
    min-height: 100svh;
  }

  .w-\(--radix-dropdown-menu-trigger-width\) {
    width: var(--radix-dropdown-menu-trigger-width);
  }

  .w-\(--sidebar-width\) {
    width: var(--sidebar-width);
  }

  .w-0 {
    width: calc(var(--spacing) * 0);
  }

  .w-1 {
    width: calc(var(--spacing) * 1);
  }

  .w-2 {
    width: calc(var(--spacing) * 2);
  }

  .w-2\.5 {
    width: calc(var(--spacing) * 2.5);
  }

  .w-3\.5 {
    width: calc(var(--spacing) * 3.5);
  }

  .w-3\/4 {
    width: 75%;
  }

  .w-4 {
    width: calc(var(--spacing) * 4);
  }

  .w-5 {
    width: calc(var(--spacing) * 5);
  }

  .w-6 {
    width: calc(var(--spacing) * 6);
  }

  .w-7 {
    width: calc(var(--spacing) * 7);
  }

  .w-8 {
    width: calc(var(--spacing) * 8);
  }

  .w-9 {
    width: calc(var(--spacing) * 9);
  }

  .w-10 {
    width: calc(var(--spacing) * 10);
  }

  .w-11 {
    width: calc(var(--spacing) * 11);
  }

  .w-12 {
    width: calc(var(--spacing) * 12);
  }

  .w-56 {
    width: calc(var(--spacing) * 56);
  }

  .w-64 {
    width: calc(var(--spacing) * 64);
  }

  .w-72 {
    width: calc(var(--spacing) * 72);
  }

  .w-\[1px\] {
    width: 1px;
  }

  .w-\[70px\] {
    width: 70px;
  }

  .w-\[100px\] {
    width: 100px;
  }

  .w-\[300px\] {
    width: 300px;
  }

  .w-auto {
    width: auto;
  }

  .w-dvw {
    width: 100dvw;
  }

  .w-fit {
    width: -moz-fit-content;
    width: fit-content;
  }

  .w-full {
    width: 100%;
  }

  .w-px {
    width: 1px;
  }

  .max-w-\(--skeleton-width\) {
    max-width: var(--skeleton-width);
  }

  .max-w-2xl {
    max-width: var(--container-2xl);
  }

  .max-w-4xl {
    max-width: var(--container-4xl);
  }

  .max-w-64 {
    max-width: calc(var(--spacing) * 64);
  }

  .max-w-\[450px\] {
    max-width: 450px;
  }

  .max-w-lg {
    max-width: var(--container-lg);
  }

  .max-w-sm {
    max-width: var(--container-sm);
  }

  .max-w-xl {
    max-width: var(--container-xl);
  }

  .min-w-0 {
    min-width: calc(var(--spacing) * 0);
  }

  .min-w-5 {
    min-width: calc(var(--spacing) * 5);
  }

  .min-w-56 {
    min-width: calc(var(--spacing) * 56);
  }

  .min-w-\[8rem\] {
    min-width: 8rem;
  }

  .min-w-\[12rem\] {
    min-width: 12rem;
  }

  .min-w-\[var\(--radix-select-trigger-width\)\] {
    min-width: var(--radix-select-trigger-width);
  }

  .flex-1 {
    flex: 1;
  }

  .flex-auto {
    flex: auto;
  }

  .shrink-0 {
    flex-shrink: 0;
  }

  .grow {
    flex-grow: 1;
  }

  .caption-bottom {
    caption-side: bottom;
  }

  .border-collapse {
    border-collapse: collapse;
  }

  .-translate-x-1\/2 {
    --tw-translate-x: calc(calc(1 / 2 * 100%) * -1);
    translate: var(--tw-translate-x) var(--tw-translate-y);
  }

  .-translate-x-px {
    --tw-translate-x: -1px;
    translate: var(--tw-translate-x) var(--tw-translate-y);
  }

  .translate-x-\[-50\%\] {
    --tw-translate-x: -50%;
    translate: var(--tw-translate-x) var(--tw-translate-y);
  }

  .translate-x-px {
    --tw-translate-x: 1px;
    translate: var(--tw-translate-x) var(--tw-translate-y);
  }

  .translate-y-\[-50\%\] {
    --tw-translate-y: -50%;
    translate: var(--tw-translate-x) var(--tw-translate-y);
  }

  .animate-caret-blink {
    animation: var(--animate-caret-blink);
  }

  .animate-pulse {
    animation: var(--animate-pulse);
  }

  .animate-spin {
    animation: var(--animate-spin);
  }

  .cursor-default {
    cursor: default;
  }

  .cursor-pointer {
    cursor: pointer;
  }

  .cursor-text {
    cursor: text;
  }

  .touch-none {
    touch-action: none;
  }

  .grid-cols-1 {
    grid-template-columns: repeat(1, minmax(0, 1fr));
  }

  .flex-col {
    flex-direction: column;
  }

  .flex-col-reverse {
    flex-direction: column-reverse;
  }

  .flex-row {
    flex-direction: row;
  }

  .flex-wrap {
    flex-wrap: wrap;
  }

  .items-center {
    align-items: center;
  }

  .items-end {
    align-items: flex-end;
  }

  .items-start {
    align-items: flex-start;
  }

  .items-stretch {
    align-items: stretch;
  }

  .justify-between {
    justify-content: space-between;
  }

  .justify-center {
    justify-content: center;
  }

  .justify-start {
    justify-content: flex-start;
  }

  .gap-1 {
    gap: calc(var(--spacing) * 1);
  }

  .gap-1\.5 {
    gap: calc(var(--spacing) * 1.5);
  }

  .gap-2 {
    gap: calc(var(--spacing) * 2);
  }

  .gap-4 {
    gap: calc(var(--spacing) * 4);
  }

  .gap-6 {
    gap: calc(var(--spacing) * 6);
  }

  :where(.space-y-0 > :not(:last-child)) {
    --tw-space-y-reverse: 0;
    margin-block-start: calc(calc(var(--spacing) * 0) * var(--tw-space-y-reverse));
    margin-block-end: calc(calc(var(--spacing) * 0) * calc(1 - var(--tw-space-y-reverse)));
  }

  :where(.space-y-1 > :not(:last-child)) {
    --tw-space-y-reverse: 0;
    margin-block-start: calc(calc(var(--spacing) * 1) * var(--tw-space-y-reverse));
    margin-block-end: calc(calc(var(--spacing) * 1) * calc(1 - var(--tw-space-y-reverse)));
  }

  :where(.space-x-1 > :not(:last-child)) {
    --tw-space-x-reverse: 0;
    margin-inline-start: calc(calc(var(--spacing) * 1) * var(--tw-space-x-reverse));
    margin-inline-end: calc(calc(var(--spacing) * 1) * calc(1 - var(--tw-space-x-reverse)));
  }

  :where(.space-x-3 > :not(:last-child)) {
    --tw-space-x-reverse: 0;
    margin-inline-start: calc(calc(var(--spacing) * 3) * var(--tw-space-x-reverse));
    margin-inline-end: calc(calc(var(--spacing) * 3) * calc(1 - var(--tw-space-x-reverse)));
  }

  .truncate {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }

  .overflow-auto {
    overflow: auto;
  }

  .overflow-hidden {
    overflow: hidden;
  }

  .overflow-x-hidden {
    overflow-x: hidden;
  }

  .overflow-y-auto {
    overflow-y: auto;
  }

  .rounded-\[2px\] {
    border-radius: 2px;
  }

  .rounded-\[inherit\] {
    border-radius: inherit;
  }

  .rounded-full {
    border-radius: 3.40282e38px;
  }

  .rounded-lg {
    border-radius: var(--radius-lg);
  }

  .rounded-md {
    border-radius: var(--radius-md);
  }

  .rounded-sm {
    border-radius: var(--radius-sm);
  }

  .rounded-t-\[10px\] {
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
  }

  .border {
    border-style: var(--tw-border-style);
    border-width: 1px;
  }

  .border-0 {
    border-style: var(--tw-border-style);
    border-width: 0;
  }

  .border-2 {
    border-style: var(--tw-border-style);
    border-width: 2px;
  }

  .border-4 {
    border-style: var(--tw-border-style);
    border-width: 4px;
  }

  .border-\[1\.5px\] {
    border-style: var(--tw-border-style);
    border-width: 1.5px;
  }

  .border-y {
    border-block-style: var(--tw-border-style);
    border-block-start-width: 1px;
    border-block-end-width: 1px;
  }

  .border-t {
    border-top-style: var(--tw-border-style);
    border-top-width: 1px;
  }

  .border-r {
    border-right-style: var(--tw-border-style);
    border-right-width: 1px;
  }

  .border-b {
    border-bottom-style: var(--tw-border-style);
    border-bottom-width: 1px;
  }

  .border-l {
    border-left-style: var(--tw-border-style);
    border-left-width: 1px;
  }

  .border-dashed {
    --tw-border-style: dashed;
    border-style: dashed;
  }

  .border-none {
    --tw-border-style: none;
    border-style: none;
  }

  .border-\(--color-border\) {
    border-color: var(--color-border);
  }

  .border-border\/50 {
    border-color: color-mix(in srgb, hsl(var(--border)) 50%, transparent);
  }

  @supports (color: color-mix(in lab, red, red)) {
    .border-border\/50 {
      border-color: color-mix(in oklab, var(--color-border) 50%, transparent);
    }
  }

  .border-input {
    border-color: var(--color-input);
  }

  .border-primary {
    border-color: var(--color-primary);
  }

  .border-sidebar-border {
    border-color: var(--color-sidebar-border);
  }

  .border-sidebar-primary {
    border-color: var(--color-sidebar-primary);
  }

  .border-transparent {
    border-color: #0000;
  }

  .border-t-transparent {
    border-top-color: #0000;
  }

  .border-l-transparent {
    border-left-color: #0000;
  }

  .bg-\(--color-bg\) {
    background-color: var(--color-bg);
  }

  .bg-accent {
    background-color: var(--color-accent);
  }

  .bg-background {
    background-color: var(--color-background);
  }

  .bg-black\/80 {
    background-color: #000c;
  }

  @supports (color: color-mix(in lab, red, red)) {
    .bg-black\/80 {
      background-color: color-mix(in oklab, var(--color-black) 80%, transparent);
    }
  }

  .bg-border {
    background-color: var(--color-border);
  }

  .bg-card {
    background-color: var(--color-card);
  }

  .bg-destructive {
    background-color: var(--color-destructive);
  }

  .bg-foreground {
    background-color: var(--color-foreground);
  }

  .bg-gray-800 {
    background-color: var(--color-gray-800);
  }

  .bg-muted {
    background-color: var(--color-muted);
  }

  .bg-muted\/50 {
    background-color: color-mix(in srgb, hsl(var(--muted)) 50%, transparent);
  }

  @supports (color: color-mix(in lab, red, red)) {
    .bg-muted\/50 {
      background-color: color-mix(in oklab, var(--color-muted) 50%, transparent);
    }
  }

  .bg-popover {
    background-color: var(--color-popover);
  }

  .bg-primary {
    background-color: var(--color-primary);
  }

  .bg-secondary {
    background-color: var(--color-secondary);
  }

  .bg-sidebar {
    background-color: var(--color-sidebar);
  }

  .bg-sidebar-border {
    background-color: var(--color-sidebar-border);
  }

  .bg-sidebar-primary {
    background-color: var(--color-sidebar-primary);
  }

  .bg-transparent {
    background-color: #0000;
  }

  .fill-current {
    fill: currentColor;
  }

  .fill-foreground {
    fill: var(--color-foreground);
  }

  .fill-muted-foreground {
    fill: var(--color-muted-foreground);
  }

  .p-0 {
    padding: calc(var(--spacing) * 0);
  }

  .p-1 {
    padding: calc(var(--spacing) * 1);
  }

  .p-2 {
    padding: calc(var(--spacing) * 2);
  }

  .p-3 {
    padding: calc(var(--spacing) * 3);
  }

  .p-4 {
    padding: calc(var(--spacing) * 4);
  }

  .p-6 {
    padding: calc(var(--spacing) * 6);
  }

  .p-\[1px\] {
    padding: 1px;
  }

  .px-1 {
    padding-inline: calc(var(--spacing) * 1);
  }

  .px-2 {
    padding-inline: calc(var(--spacing) * 2);
  }

  .px-2\.5 {
    padding-inline: calc(var(--spacing) * 2.5);
  }

  .px-3 {
    padding-inline: calc(var(--spacing) * 3);
  }

  .px-4 {
    padding-inline: calc(var(--spacing) * 4);
  }

  .px-8 {
    padding-inline: calc(var(--spacing) * 8);
  }

  .py-0\.5 {
    padding-block: calc(var(--spacing) * .5);
  }

  .py-1 {
    padding-block: calc(var(--spacing) * 1);
  }

  .py-1\.5 {
    padding-block: calc(var(--spacing) * 1.5);
  }

  .py-2 {
    padding-block: calc(var(--spacing) * 2);
  }

  .py-3 {
    padding-block: calc(var(--spacing) * 3);
  }

  .py-6 {
    padding-block: calc(var(--spacing) * 6);
  }

  .pt-0 {
    padding-top: calc(var(--spacing) * 0);
  }

  .pt-1 {
    padding-top: calc(var(--spacing) * 1);
  }

  .pt-3 {
    padding-top: calc(var(--spacing) * 3);
  }

  .pr-0 {
    padding-right: calc(var(--spacing) * 0);
  }

  .pr-2 {
    padding-right: calc(var(--spacing) * 2);
  }

  .pr-4 {
    padding-right: calc(var(--spacing) * 4);
  }

  .pb-0 {
    padding-bottom: calc(var(--spacing) * 0);
  }

  .pb-3 {
    padding-bottom: calc(var(--spacing) * 3);
  }

  .pb-4 {
    padding-bottom: calc(var(--spacing) * 4);
  }

  .pl-0\.5 {
    padding-left: calc(var(--spacing) * .5);
  }

  .pl-2 {
    padding-left: calc(var(--spacing) * 2);
  }

  .pl-8 {
    padding-left: calc(var(--spacing) * 8);
  }

  .text-center {
    text-align: center;
  }

  .text-left {
    text-align: left;
  }

  .align-middle {
    vertical-align: middle;
  }

  .font-mono {
    font-family: var(--font-mono);
  }

  .text-2xl {
    font-size: var(--text-2xl);
    line-height: var(--tw-leading, var(--text-2xl--line-height));
  }

  .text-3xl {
    font-size: var(--text-3xl);
    line-height: var(--tw-leading, var(--text-3xl--line-height));
  }

  .text-base {
    font-size: var(--text-base);
    line-height: var(--tw-leading, var(--text-base--line-height));
  }

  .text-lg {
    font-size: var(--text-lg);
    line-height: var(--tw-leading, var(--text-lg--line-height));
  }

  .text-sm {
    font-size: var(--text-sm);
    line-height: var(--tw-leading, var(--text-sm--line-height));
  }

  .text-xl {
    font-size: var(--text-xl);
    line-height: var(--tw-leading, var(--text-xl--line-height));
  }

  .text-xs {
    font-size: var(--text-xs);
    line-height: var(--tw-leading, var(--text-xs--line-height));
  }

  .text-\[0\.8rem\] {
    font-size: .8rem;
  }

  .leading-none {
    --tw-leading: 1;
    line-height: 1;
  }

  .leading-tight {
    --tw-leading: var(--leading-tight);
    line-height: var(--leading-tight);
  }

  .font-bold {
    --tw-font-weight: var(--font-weight-bold);
    font-weight: var(--font-weight-bold);
  }

  .font-medium {
    --tw-font-weight: var(--font-weight-medium);
    font-weight: var(--font-weight-medium);
  }

  .font-normal {
    --tw-font-weight: var(--font-weight-normal);
    font-weight: var(--font-weight-normal);
  }

  .font-semibold {
    --tw-font-weight: var(--font-weight-semibold);
    font-weight: var(--font-weight-semibold);
  }

  .tracking-tight {
    --tw-tracking: var(--tracking-tight);
    letter-spacing: var(--tracking-tight);
  }

  .tracking-wide {
    --tw-tracking: var(--tracking-wide);
    letter-spacing: var(--tracking-wide);
  }

  .tracking-widest {
    --tw-tracking: var(--tracking-widest);
    letter-spacing: var(--tracking-widest);
  }

  .text-balance {
    text-wrap: balance;
  }

  .text-nowrap {
    text-wrap: nowrap;
  }

  .break-words {
    overflow-wrap: break-word;
  }

  .break-all {
    word-break: break-all;
  }

  .whitespace-nowrap {
    white-space: nowrap;
  }

  .whitespace-pre-line {
    white-space: pre-line;
  }

  .whitespace-pre-wrap {
    white-space: pre-wrap;
  }

  .text-accent-foreground {
    color: var(--color-accent-foreground);
  }

  .text-blue-500 {
    color: var(--color-blue-500);
  }

  .text-card-foreground {
    color: var(--color-card-foreground);
  }

  .text-current {
    color: currentColor;
  }

  .text-destructive {
    color: var(--color-destructive);
  }

  .text-destructive-foreground {
    color: var(--color-destructive-foreground);
  }

  .text-foreground {
    color: var(--color-foreground);
  }

  .text-green-500 {
    color: var(--color-green-500);
  }

  .text-muted-foreground {
    color: var(--color-muted-foreground);
  }

  .text-popover-foreground {
    color: var(--color-popover-foreground);
  }

  .text-primary {
    color: var(--color-primary);
  }

  .text-primary-foreground {
    color: var(--color-primary-foreground);
  }

  .text-red-500 {
    color: var(--color-red-500);
  }

  .text-secondary-foreground {
    color: var(--color-secondary-foreground);
  }

  .text-sidebar-foreground {
    color: var(--color-sidebar-foreground);
  }

  .text-sidebar-foreground\/70 {
    color: color-mix(in srgb, hsl(var(--sidebar-foreground)) 70%, transparent);
  }

  @supports (color: color-mix(in lab, red, red)) {
    .text-sidebar-foreground\/70 {
      color: color-mix(in oklab, var(--color-sidebar-foreground) 70%, transparent);
    }
  }

  .text-sidebar-primary-foreground {
    color: var(--color-sidebar-primary-foreground);
  }

  .text-white {
    color: var(--color-white);
  }

  .uppercase {
    text-transform: uppercase;
  }

  .tabular-nums {
    --tw-numeric-spacing: tabular-nums;
    font-variant-numeric: var(--tw-ordinal, ) var(--tw-slashed-zero, ) var(--tw-numeric-figure, ) var(--tw-numeric-spacing, ) var(--tw-numeric-fraction, );
  }

  .underline-offset-4 {
    text-underline-offset: 4px;
  }

  .opacity-0 {
    opacity: 0;
  }

  .opacity-50 {
    opacity: .5;
  }

  .opacity-60 {
    opacity: .6;
  }

  .opacity-70 {
    opacity: .7;
  }

  .opacity-100 {
    opacity: 1;
  }

  .shadow-\[0_0_0_1px_hsl\(var\(--sidebar-border\)\)\] {
    --tw-shadow: 0 0 0 1px var(--tw-shadow-color, hsl(var(--sidebar-border)));
    box-shadow: var(--tw-inset-shadow), var(--tw-inset-ring-shadow), var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow);
  }

  .shadow-lg {
    --tw-shadow: 0 10px 15px -3px var(--tw-shadow-color, #0000001a), 0 4px 6px -4px var(--tw-shadow-color, #0000001a);
    box-shadow: var(--tw-inset-shadow), var(--tw-inset-ring-shadow), var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow);
  }

  .shadow-md {
    --tw-shadow: 0 4px 6px -1px var(--tw-shadow-color, #0000001a), 0 2px 4px -2px var(--tw-shadow-color, #0000001a);
    box-shadow: var(--tw-inset-shadow), var(--tw-inset-ring-shadow), var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow);
  }

  .shadow-none {
    --tw-shadow: 0 0 #0000;
    box-shadow: var(--tw-inset-shadow), var(--tw-inset-ring-shadow), var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow);
  }

  .shadow-xl {
    --tw-shadow: 0 20px 25px -5px var(--tw-shadow-color, #0000001a), 0 8px 10px -6px var(--tw-shadow-color, #0000001a);
    box-shadow: var(--tw-inset-shadow), var(--tw-inset-ring-shadow), var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow);
  }

  .shadow-xs {
    --tw-shadow: 0 1px 2px 0 var(--tw-shadow-color, #0000000d);
    box-shadow: var(--tw-inset-shadow), var(--tw-inset-ring-shadow), var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow);
  }

  .ring-0 {
    --tw-ring-shadow: var(--tw-ring-inset, ) 0 0 0 calc(0px + var(--tw-ring-offset-width)) var(--tw-ring-color, currentColor);
    box-shadow: var(--tw-inset-shadow), var(--tw-inset-ring-shadow), var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow);
  }

  .ring-2 {
    --tw-ring-shadow: var(--tw-ring-inset, ) 0 0 0 calc(2px + var(--tw-ring-offset-width)) var(--tw-ring-color, currentColor);
    box-shadow: var(--tw-inset-shadow), var(--tw-inset-ring-shadow), var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow);
  }

  .ring-ring {
    --tw-ring-color: var(--color-ring);
  }

  .ring-sidebar-ring {
    --tw-ring-color: var(--color-sidebar-ring);
  }

  .ring-offset-background {
    --tw-ring-offset-color: var(--color-background);
  }

  .outline-hidden {
    --tw-outline-style: none;
    outline-style: none;
  }

  @media (forced-colors: active) {
    .outline-hidden {
      outline-offset: 2px;
      outline: 2px solid #0000;
    }
  }

  .outline {
    outline-style: var(--tw-outline-style);
    outline-width: 1px;
  }

  .filter {
    filter: var(--tw-blur, ) var(--tw-brightness, ) var(--tw-contrast, ) var(--tw-grayscale, ) var(--tw-hue-rotate, ) var(--tw-invert, ) var(--tw-saturate, ) var(--tw-sepia, ) var(--tw-drop-shadow, );
  }

  .transition {
    transition-property: color, background-color, border-color, outline-color, text-decoration-color, fill, stroke, --tw-gradient-from, --tw-gradient-via, --tw-gradient-to, opacity, box-shadow, transform, translate, scale, rotate, filter, -webkit-backdrop-filter, backdrop-filter;
    transition-timing-function: var(--tw-ease, var(--default-transition-timing-function));
    transition-duration: var(--tw-duration, var(--default-transition-duration));
  }

  .transition-\[left\,right\,width\] {
    transition-property: left, right, width;
    transition-timing-function: var(--tw-ease, var(--default-transition-timing-function));
    transition-duration: var(--tw-duration, var(--default-transition-duration));
  }

  .transition-\[margin\,opa\] {
    transition-property: margin, opa;
    transition-timing-function: var(--tw-ease, var(--default-transition-timing-function));
    transition-duration: var(--tw-duration, var(--default-transition-duration));
  }

  .transition-\[width\,height\,padding\] {
    transition-property: width, height, padding;
    transition-timing-function: var(--tw-ease, var(--default-transition-timing-function));
    transition-duration: var(--tw-duration, var(--default-transition-duration));
  }

  .transition-\[width\] {
    transition-property: width;
    transition-timing-function: var(--tw-ease, var(--default-transition-timing-function));
    transition-duration: var(--tw-duration, var(--default-transition-duration));
  }

  .transition-all {
    transition-property: all;
    transition-timing-function: var(--tw-ease, var(--default-transition-timing-function));
    transition-duration: var(--tw-duration, var(--default-transition-duration));
  }

  .transition-colors {
    transition-property: color, background-color, border-color, outline-color, text-decoration-color, fill, stroke, --tw-gradient-from, --tw-gradient-via, --tw-gradient-to;
    transition-timing-function: var(--tw-ease, var(--default-transition-timing-function));
    transition-duration: var(--tw-duration, var(--default-transition-duration));
  }

  .transition-opacity {
    transition-property: opacity;
    transition-timing-function: var(--tw-ease, var(--default-transition-timing-function));
    transition-duration: var(--tw-duration, var(--default-transition-duration));
  }

  .transition-transform {
    transition-property: transform, translate, scale, rotate;
    transition-timing-function: var(--tw-ease, var(--default-transition-timing-function));
    transition-duration: var(--tw-duration, var(--default-transition-duration));
  }

  .duration-200 {
    --tw-duration: .2s;
    transition-duration: .2s;
  }

  .duration-1000 {
    --tw-duration: 1s;
    transition-duration: 1s;
  }

  .ease-in-out {
    --tw-ease: var(--ease-in-out);
    transition-timing-function: var(--ease-in-out);
  }

  .ease-linear {
    --tw-ease: linear;
    transition-timing-function: linear;
  }

  .select-all {
    -webkit-user-select: all;
    user-select: all;
  }

  .select-none {
    -webkit-user-select: none;
    user-select: none;
  }

  .select-text {
    -webkit-user-select: text;
    user-select: text;
  }

  .group-focus-within\/menu-item\:opacity-100:is(:where(.group\/menu-item):focus-within *) {
    opacity: 1;
  }

  @media (hover: hover) {
    .group-hover\/menu-item\:opacity-100:is(:where(.group\/menu-item):hover *) {
      opacity: 1;
    }
  }

  .group-has-data-\[sidebar\=menu-action\]\/menu-item\:pr-8:is(:where(.group\/menu-item):has([data-sidebar="menu-action"]) *) {
    padding-right: calc(var(--spacing) * 8);
  }

  .group-data-\[collapsible\=icon\]\:-mt-8:is(:where(.group)[data-collapsible="icon"] *) {
    margin-top: calc(var(--spacing) * -8);
  }

  .group-data-\[collapsible\=icon\]\:hidden:is(:where(.group)[data-collapsible="icon"] *) {
    display: none;
  }

  .group-data-\[collapsible\=icon\]\:size-8\!:is(:where(.group)[data-collapsible="icon"] *) {
    width: calc(var(--spacing) * 8) !important;
    height: calc(var(--spacing) * 8) !important;
  }

  .group-data-\[collapsible\=icon\]\:w-\(--sidebar-width-icon\):is(:where(.group)[data-collapsible="icon"] *) {
    width: var(--sidebar-width-icon);
  }

  .group-data-\[collapsible\=icon\]\:w-\[calc\(var\(--sidebar-width-icon\)\+\(--spacing\(4\)\)\)\]:is(:where(.group)[data-collapsible="icon"] *) {
    width: calc(var(--sidebar-width-icon)  + (calc(var(--spacing) * 4)));
  }

  .group-data-\[collapsible\=icon\]\:w-\[calc\(var\(--sidebar-width-icon\)\+\(--spacing\(4\)\)\+2px\)\]:is(:where(.group)[data-collapsible="icon"] *) {
    width: calc(var(--sidebar-width-icon)  + (calc(var(--spacing) * 4))  + 2px);
  }

  .group-data-\[collapsible\=icon\]\:overflow-hidden:is(:where(.group)[data-collapsible="icon"] *) {
    overflow: hidden;
  }

  .group-data-\[collapsible\=icon\]\:p-0\!:is(:where(.group)[data-collapsible="icon"] *) {
    padding: calc(var(--spacing) * 0) !important;
  }

  .group-data-\[collapsible\=icon\]\:p-2\!:is(:where(.group)[data-collapsible="icon"] *) {
    padding: calc(var(--spacing) * 2) !important;
  }

  .group-data-\[collapsible\=icon\]\:opacity-0:is(:where(.group)[data-collapsible="icon"] *) {
    opacity: 0;
  }

  .group-data-\[collapsible\=offcanvas\]\:right-\[calc\(var\(--sidebar-width\)\*-1\)\]:is(:where(.group)[data-collapsible="offcanvas"] *) {
    right: calc(var(--sidebar-width) * -1);
  }

  .group-data-\[collapsible\=offcanvas\]\:left-\[calc\(var\(--sidebar-width\)\*-1\)\]:is(:where(.group)[data-collapsible="offcanvas"] *) {
    left: calc(var(--sidebar-width) * -1);
  }

  .group-data-\[collapsible\=offcanvas\]\:w-0:is(:where(.group)[data-collapsible="offcanvas"] *) {
    width: calc(var(--spacing) * 0);
  }

  .group-data-\[collapsible\=offcanvas\]\:translate-x-0:is(:where(.group)[data-collapsible="offcanvas"] *) {
    --tw-translate-x: calc(var(--spacing) * 0);
    translate: var(--tw-translate-x) var(--tw-translate-y);
  }

  .group-data-\[side\=left\]\:-right-4:is(:where(.group)[data-side="left"] *) {
    right: calc(var(--spacing) * -4);
  }

  .group-data-\[side\=left\]\:border-r:is(:where(.group)[data-side="left"] *) {
    border-right-style: var(--tw-border-style);
    border-right-width: 1px;
  }

  .group-data-\[side\=right\]\:left-0:is(:where(.group)[data-side="right"] *) {
    left: calc(var(--spacing) * 0);
  }

  .group-data-\[side\=right\]\:rotate-180:is(:where(.group)[data-side="right"] *) {
    rotate: 180deg;
  }

  .group-data-\[side\=right\]\:border-l:is(:where(.group)[data-side="right"] *) {
    border-left-style: var(--tw-border-style);
    border-left-width: 1px;
  }

  .group-data-\[state\=open\]\/collapsible\:rotate-90:is(:where(.group\/collapsible)[data-state="open"] *) {
    rotate: 90deg;
  }

  .group-data-\[variant\=floating\]\:rounded-lg:is(:where(.group)[data-variant="floating"] *) {
    border-radius: var(--radius-lg);
  }

  .group-data-\[variant\=floating\]\:border:is(:where(.group)[data-variant="floating"] *) {
    border-style: var(--tw-border-style);
    border-width: 1px;
  }

  .group-data-\[variant\=floating\]\:border-sidebar-border:is(:where(.group)[data-variant="floating"] *) {
    border-color: var(--color-sidebar-border);
  }

  .group-data-\[variant\=floating\]\:shadow-sm:is(:where(.group)[data-variant="floating"] *) {
    --tw-shadow: 0 1px 3px 0 var(--tw-shadow-color, #0000001a), 0 1px 2px -1px var(--tw-shadow-color, #0000001a);
    box-shadow: var(--tw-inset-shadow), var(--tw-inset-ring-shadow), var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow);
  }

  .group-\[\.toast\]\:bg-muted:is(:where(.group).toast *) {
    background-color: var(--color-muted);
  }

  .group-\[\.toast\]\:bg-primary:is(:where(.group).toast *) {
    background-color: var(--color-primary);
  }

  .group-\[\.toast\]\:text-muted-foreground:is(:where(.group).toast *) {
    color: var(--color-muted-foreground);
  }

  .group-\[\.toast\]\:text-primary-foreground:is(:where(.group).toast *) {
    color: var(--color-primary-foreground);
  }

  .group-\[\.toaster\]\:border-border:is(:where(.group).toaster *) {
    border-color: var(--color-border);
  }

  .group-\[\.toaster\]\:bg-background:is(:where(.group).toaster *) {
    background-color: var(--color-background);
  }

  .group-\[\.toaster\]\:text-foreground:is(:where(.group).toaster *) {
    color: var(--color-foreground);
  }

  .group-\[\.toaster\]\:shadow-lg:is(:where(.group).toaster *) {
    --tw-shadow: 0 10px 15px -3px var(--tw-shadow-color, #0000001a), 0 4px 6px -4px var(--tw-shadow-color, #0000001a);
    box-shadow: var(--tw-inset-shadow), var(--tw-inset-ring-shadow), var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow);
  }

  @media (hover: hover) {
    .peer-hover\/menu-button\:text-sidebar-accent-foreground:is(:where(.peer\/menu-button):hover ~ *) {
      color: var(--color-sidebar-accent-foreground);
    }
  }

  .peer-disabled\:cursor-not-allowed:is(:where(.peer):disabled ~ *) {
    cursor: not-allowed;
  }

  .peer-disabled\:opacity-70:is(:where(.peer):disabled ~ *) {
    opacity: .7;
  }

  .peer-data-\[active\=true\]\/menu-button\:text-sidebar-accent-foreground:is(:where(.peer\/menu-button)[data-active="true"] ~ *) {
    color: var(--color-sidebar-accent-foreground);
  }

  .peer-data-\[size\=default\]\/menu-button\:top-1\.5:is(:where(.peer\/menu-button)[data-size="default"] ~ *) {
    top: calc(var(--spacing) * 1.5);
  }

  .peer-data-\[size\=lg\]\/menu-button\:top-2\.5:is(:where(.peer\/menu-button)[data-size="lg"] ~ *) {
    top: calc(var(--spacing) * 2.5);
  }

  .peer-data-\[size\=sm\]\/menu-button\:top-1:is(:where(.peer\/menu-button)[data-size="sm"] ~ *) {
    top: calc(var(--spacing) * 1);
  }

  .peer-data-\[variant\=inset\]\:min-h-\[calc\(100svh-\(--spacing\(4\)\)\)\]:is(:where(.peer)[data-variant="inset"] ~ *) {
    min-height: calc(100svh - (calc(var(--spacing) * 4)));
  }

  .selection\:bg-\(--terminal-selection-bg\)\/25 ::selection, .selection\:bg-\(--terminal-selection-bg\)\/25::selection {
    background-color: color-mix(in oklab, var(--terminal-selection-bg) 25%, transparent);
  }

  .file\:border-0::-webkit-file-upload-button {
    border-style: var(--tw-border-style);
    border-width: 0;
  }

  .file\:border-0::file-selector-button {
    border-style: var(--tw-border-style);
    border-width: 0;
  }

  .file\:bg-transparent::-webkit-file-upload-button {
    background-color: #0000;
  }

  .file\:bg-transparent::file-selector-button {
    background-color: #0000;
  }

  .file\:text-sm::-webkit-file-upload-button {
    font-size: var(--text-sm);
    line-height: var(--tw-leading, var(--text-sm--line-height));
  }

  .file\:text-sm::file-selector-button {
    font-size: var(--text-sm);
    line-height: var(--tw-leading, var(--text-sm--line-height));
  }

  .file\:font-medium::-webkit-file-upload-button {
    --tw-font-weight: var(--font-weight-medium);
    font-weight: var(--font-weight-medium);
  }

  .file\:font-medium::file-selector-button {
    --tw-font-weight: var(--font-weight-medium);
    font-weight: var(--font-weight-medium);
  }

  .file\:text-foreground::-webkit-file-upload-button {
    color: var(--color-foreground);
  }

  .file\:text-foreground::file-selector-button {
    color: var(--color-foreground);
  }

  .placeholder\:text-muted-foreground::placeholder {
    color: var(--color-muted-foreground);
  }

  .after\:absolute:after {
    content: var(--tw-content);
    position: absolute;
  }

  .after\:-inset-2:after {
    content: var(--tw-content);
    inset: calc(var(--spacing) * -2);
  }

  .after\:inset-y-0:after {
    content: var(--tw-content);
    inset-block: calc(var(--spacing) * 0);
  }

  .after\:left-1\/2:after {
    content: var(--tw-content);
    left: 50%;
  }

  .after\:w-\[2px\]:after {
    content: var(--tw-content);
    width: 2px;
  }

  .group-data-\[collapsible\=offcanvas\]\:after\:left-full:is(:where(.group)[data-collapsible="offcanvas"] *):after {
    content: var(--tw-content);
    left: 100%;
  }

  .first\:rounded-l-md:first-child {
    border-top-left-radius: var(--radius-md);
    border-bottom-left-radius: var(--radius-md);
  }

  .first\:border-l:first-child {
    border-left-style: var(--tw-border-style);
    border-left-width: 1px;
  }

  .last\:rounded-r-md:last-child {
    border-top-right-radius: var(--radius-md);
    border-bottom-right-radius: var(--radius-md);
  }

  .focus-within\:relative:focus-within {
    position: relative;
  }

  .focus-within\:z-20:focus-within {
    z-index: 20;
  }

  @media (hover: hover) {
    .hover\:bg-accent:hover {
      background-color: var(--color-accent);
    }

    .hover\:bg-destructive\/80:hover {
      background-color: color-mix(in srgb, hsl(var(--destructive)) 80%, transparent);
    }

    @supports (color: color-mix(in lab, red, red)) {
      .hover\:bg-destructive\/80:hover {
        background-color: color-mix(in oklab, var(--color-destructive) 80%, transparent);
      }
    }

    .hover\:bg-destructive\/90:hover {
      background-color: color-mix(in srgb, hsl(var(--destructive)) 90%, transparent);
    }

    @supports (color: color-mix(in lab, red, red)) {
      .hover\:bg-destructive\/90:hover {
        background-color: color-mix(in oklab, var(--color-destructive) 90%, transparent);
      }
    }

    .hover\:bg-muted\/50:hover {
      background-color: color-mix(in srgb, hsl(var(--muted)) 50%, transparent);
    }

    @supports (color: color-mix(in lab, red, red)) {
      .hover\:bg-muted\/50:hover {
        background-color: color-mix(in oklab, var(--color-muted) 50%, transparent);
      }
    }

    .hover\:bg-primary:hover {
      background-color: var(--color-primary);
    }

    .hover\:bg-primary\/80:hover {
      background-color: color-mix(in srgb, hsl(var(--primary)) 80%, transparent);
    }

    @supports (color: color-mix(in lab, red, red)) {
      .hover\:bg-primary\/80:hover {
        background-color: color-mix(in oklab, var(--color-primary) 80%, transparent);
      }
    }

    .hover\:bg-primary\/90:hover {
      background-color: color-mix(in srgb, hsl(var(--primary)) 90%, transparent);
    }

    @supports (color: color-mix(in lab, red, red)) {
      .hover\:bg-primary\/90:hover {
        background-color: color-mix(in oklab, var(--color-primary) 90%, transparent);
      }
    }

    .hover\:bg-secondary\/80:hover {
      background-color: color-mix(in srgb, hsl(var(--secondary)) 80%, transparent);
    }

    @supports (color: color-mix(in lab, red, red)) {
      .hover\:bg-secondary\/80:hover {
        background-color: color-mix(in oklab, var(--color-secondary) 80%, transparent);
      }
    }

    .hover\:bg-sidebar-accent:hover {
      background-color: var(--color-sidebar-accent);
    }

    .hover\:text-accent-foreground:hover {
      color: var(--color-accent-foreground);
    }

    .hover\:text-foreground:hover {
      color: var(--color-foreground);
    }

    .hover\:text-primary-foreground:hover {
      color: var(--color-primary-foreground);
    }

    .hover\:text-sidebar-accent-foreground:hover {
      color: var(--color-sidebar-accent-foreground);
    }

    .hover\:underline:hover {
      text-decoration-line: underline;
    }

    .hover\:opacity-100:hover {
      opacity: 1;
    }

    .hover\:shadow-\[0_0_0_1px_hsl\(var\(--sidebar-accent\)\)\]:hover {
      --tw-shadow: 0 0 0 1px var(--tw-shadow-color, hsl(var(--sidebar-accent)));
      box-shadow: var(--tw-inset-shadow), var(--tw-inset-ring-shadow), var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow);
    }

    .hover\:group-data-\[collapsible\=offcanvas\]\:bg-sidebar:hover:is(:where(.group)[data-collapsible="offcanvas"] *) {
      background-color: var(--color-sidebar);
    }

    .hover\:after\:bg-sidebar-border:hover:after {
      content: var(--tw-content);
      background-color: var(--color-sidebar-border);
    }
  }

  .focus\:bg-accent:focus {
    background-color: var(--color-accent);
  }

  .focus\:bg-primary:focus {
    background-color: var(--color-primary);
  }

  .focus\:text-accent-foreground:focus {
    color: var(--color-accent-foreground);
  }

  .focus\:text-primary-foreground:focus {
    color: var(--color-primary-foreground);
  }

  .focus\:ring-2:focus {
    --tw-ring-shadow: var(--tw-ring-inset, ) 0 0 0 calc(2px + var(--tw-ring-offset-width)) var(--tw-ring-color, currentColor);
    box-shadow: var(--tw-inset-shadow), var(--tw-inset-ring-shadow), var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow);
  }

  .focus\:ring-ring:focus {
    --tw-ring-color: var(--color-ring);
  }

  .focus\:ring-offset-2:focus {
    --tw-ring-offset-width: 2px;
    --tw-ring-offset-shadow: var(--tw-ring-inset, ) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  }

  .focus\:outline-hidden:focus {
    --tw-outline-style: none;
    outline-style: none;
  }

  @media (forced-colors: active) {
    .focus\:outline-hidden:focus {
      outline-offset: 2px;
      outline: 2px solid #0000;
    }
  }

  .focus-visible\:ring-2:focus-visible {
    --tw-ring-shadow: var(--tw-ring-inset, ) 0 0 0 calc(2px + var(--tw-ring-offset-width)) var(--tw-ring-color, currentColor);
    box-shadow: var(--tw-inset-shadow), var(--tw-inset-ring-shadow), var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow);
  }

  .focus-visible\:ring-ring:focus-visible {
    --tw-ring-color: var(--color-ring);
  }

  .focus-visible\:ring-sidebar-ring:focus-visible {
    --tw-ring-color: var(--color-sidebar-ring);
  }

  .focus-visible\:ring-offset-2:focus-visible {
    --tw-ring-offset-width: 2px;
    --tw-ring-offset-shadow: var(--tw-ring-inset, ) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  }

  .focus-visible\:ring-offset-background:focus-visible {
    --tw-ring-offset-color: var(--color-background);
  }

  .focus-visible\:outline-hidden:focus-visible {
    --tw-outline-style: none;
    outline-style: none;
  }

  @media (forced-colors: active) {
    .focus-visible\:outline-hidden:focus-visible {
      outline-offset: 2px;
      outline: 2px solid #0000;
    }
  }

  .focus-visible\:outline-none:focus-visible {
    --tw-outline-style: none;
    outline-style: none;
  }

  .active\:bg-sidebar-accent:active {
    background-color: var(--color-sidebar-accent);
  }

  .active\:text-sidebar-accent-foreground:active {
    color: var(--color-sidebar-accent-foreground);
  }

  .disabled\:pointer-events-none:disabled {
    pointer-events: none;
  }

  .disabled\:cursor-not-allowed:disabled {
    cursor: not-allowed;
  }

  .disabled\:opacity-50:disabled {
    opacity: .5;
  }

  :where([data-side="left"]) .in-data-\[side\=left\]\:cursor-w-resize {
    cursor: w-resize;
  }

  :where([data-side="right"]) .in-data-\[side\=right\]\:cursor-e-resize {
    cursor: e-resize;
  }

  .has-disabled\:opacity-50:has(:disabled) {
    opacity: .5;
  }

  .has-data-\[variant\=inset\]\:bg-sidebar:has([data-variant="inset"]) {
    background-color: var(--color-sidebar);
  }

  .aria-disabled\:pointer-events-none[aria-disabled="true"] {
    pointer-events: none;
  }

  .aria-disabled\:opacity-50[aria-disabled="true"] {
    opacity: .5;
  }

  .aria-selected\:bg-accent[aria-selected="true"] {
    background-color: var(--color-accent);
  }

  .aria-selected\:bg-primary[aria-selected="true"] {
    background-color: var(--color-primary);
  }

  .aria-selected\:text-accent-foreground[aria-selected="true"] {
    color: var(--color-accent-foreground);
  }

  .aria-selected\:text-muted-foreground[aria-selected="true"] {
    color: var(--color-muted-foreground);
  }

  .aria-selected\:text-primary-foreground[aria-selected="true"] {
    color: var(--color-primary-foreground);
  }

  .aria-selected\:opacity-100[aria-selected="true"] {
    opacity: 1;
  }

  .data-disabled\:pointer-events-none[data-disabled] {
    pointer-events: none;
  }

  .data-disabled\:opacity-50[data-disabled] {
    opacity: .5;
  }

  .data-\[active\=true\]\:bg-sidebar-accent[data-active="true"] {
    background-color: var(--color-sidebar-accent);
  }

  .data-\[active\=true\]\:font-medium[data-active="true"] {
    --tw-font-weight: var(--font-weight-medium);
    font-weight: var(--font-weight-medium);
  }

  .data-\[active\=true\]\:text-sidebar-accent-foreground[data-active="true"] {
    color: var(--color-sidebar-accent-foreground);
  }

  .data-\[disabled\=true\]\:pointer-events-none[data-disabled="true"] {
    pointer-events: none;
  }

  .data-\[disabled\=true\]\:opacity-50[data-disabled="true"] {
    opacity: .5;
  }

  .data-\[selected\=\'true\'\]\:bg-accent[data-selected="true"] {
    background-color: var(--color-accent);
  }

  .data-\[selected\=true\]\:text-accent-foreground[data-selected="true"] {
    color: var(--color-accent-foreground);
  }

  .data-\[side\=bottom\]\:translate-y-1[data-side="bottom"] {
    --tw-translate-y: calc(var(--spacing) * 1);
    translate: var(--tw-translate-x) var(--tw-translate-y);
  }

  .data-\[side\=left\]\:-translate-x-1[data-side="left"] {
    --tw-translate-x: calc(var(--spacing) * -1);
    translate: var(--tw-translate-x) var(--tw-translate-y);
  }

  .data-\[side\=right\]\:translate-x-1[data-side="right"] {
    --tw-translate-x: calc(var(--spacing) * 1);
    translate: var(--tw-translate-x) var(--tw-translate-y);
  }

  .data-\[side\=top\]\:-translate-y-1[data-side="top"] {
    --tw-translate-y: calc(var(--spacing) * -1);
    translate: var(--tw-translate-x) var(--tw-translate-y);
  }

  .data-\[state\=checked\]\:translate-x-5[data-state="checked"] {
    --tw-translate-x: calc(var(--spacing) * 5);
    translate: var(--tw-translate-x) var(--tw-translate-y);
  }

  .data-\[state\=checked\]\:bg-primary[data-state="checked"] {
    background-color: var(--color-primary);
  }

  .data-\[state\=checked\]\:text-primary-foreground[data-state="checked"] {
    color: var(--color-primary-foreground);
  }

  .data-\[state\=closed\]\:duration-300[data-state="closed"] {
    --tw-duration: .3s;
    transition-duration: .3s;
  }

  .data-\[state\=open\]\:bg-accent[data-state="open"] {
    background-color: var(--color-accent);
  }

  .data-\[state\=open\]\:bg-secondary[data-state="open"] {
    background-color: var(--color-secondary);
  }

  .data-\[state\=open\]\:bg-sidebar-accent[data-state="open"] {
    background-color: var(--color-sidebar-accent);
  }

  .data-\[state\=open\]\:text-accent-foreground[data-state="open"] {
    color: var(--color-accent-foreground);
  }

  .data-\[state\=open\]\:text-muted-foreground[data-state="open"] {
    color: var(--color-muted-foreground);
  }

  .data-\[state\=open\]\:text-sidebar-accent-foreground[data-state="open"] {
    color: var(--color-sidebar-accent-foreground);
  }

  .data-\[state\=open\]\:opacity-100[data-state="open"] {
    opacity: 1;
  }

  .data-\[state\=open\]\:duration-500[data-state="open"] {
    --tw-duration: .5s;
    transition-duration: .5s;
  }

  @media (hover: hover) {
    .data-\[state\=open\]\:hover\:bg-sidebar-accent[data-state="open"]:hover {
      background-color: var(--color-sidebar-accent);
    }

    .data-\[state\=open\]\:hover\:text-sidebar-accent-foreground[data-state="open"]:hover {
      color: var(--color-sidebar-accent-foreground);
    }
  }

  .data-\[state\=selected\]\:bg-muted[data-state="selected"] {
    background-color: var(--color-muted);
  }

  .data-\[state\=unchecked\]\:translate-x-0[data-state="unchecked"] {
    --tw-translate-x: calc(var(--spacing) * 0);
    translate: var(--tw-translate-x) var(--tw-translate-y);
  }

  .data-\[state\=unchecked\]\:bg-input[data-state="unchecked"] {
    background-color: var(--color-input);
  }

  @media (min-width: 40rem) {
    .sm\:block {
      display: block;
    }

    .sm\:flex {
      display: flex;
    }

    .sm\:hidden {
      display: none;
    }

    .sm\:max-w-sm {
      max-width: var(--container-sm);
    }

    .sm\:flex-row {
      flex-direction: row;
    }

    .sm\:justify-end {
      justify-content: flex-end;
    }

    .sm\:gap-2 {
      gap: calc(var(--spacing) * 2);
    }

    .sm\:gap-2\.5 {
      gap: calc(var(--spacing) * 2.5);
    }

    .sm\:rounded-lg {
      border-radius: var(--radius-lg);
    }

    .sm\:text-left {
      text-align: left;
    }
  }

  @media (min-width: 48rem) {
    .md\:block {
      display: block;
    }

    .md\:flex {
      display: flex;
    }

    .md\:hidden {
      display: none;
    }

    .md\:grid-cols-2 {
      grid-template-columns: repeat(2, minmax(0, 1fr));
    }

    .md\:px-0 {
      padding-inline: calc(var(--spacing) * 0);
    }

    .md\:pb-0 {
      padding-bottom: calc(var(--spacing) * 0);
    }

    .md\:text-sm {
      font-size: var(--text-sm);
      line-height: var(--tw-leading, var(--text-sm--line-height));
    }

    .md\:opacity-0 {
      opacity: 0;
    }

    .md\:peer-data-\[variant\=inset\]\:m-2:is(:where(.peer)[data-variant="inset"] ~ *) {
      margin: calc(var(--spacing) * 2);
    }

    .md\:peer-data-\[variant\=inset\]\:ml-0:is(:where(.peer)[data-variant="inset"] ~ *) {
      margin-left: calc(var(--spacing) * 0);
    }

    .md\:peer-data-\[variant\=inset\]\:rounded-xl:is(:where(.peer)[data-variant="inset"] ~ *) {
      border-radius: var(--radius-xl);
    }

    .md\:peer-data-\[variant\=inset\]\:shadow-sm:is(:where(.peer)[data-variant="inset"] ~ *) {
      --tw-shadow: 0 1px 3px 0 var(--tw-shadow-color, #0000001a), 0 1px 2px -1px var(--tw-shadow-color, #0000001a);
      box-shadow: var(--tw-inset-shadow), var(--tw-inset-ring-shadow), var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow);
    }

    .md\:peer-data-\[variant\=inset\]\:peer-data-\[state\=collapsed\]\:ml-2:is(:where(.peer)[data-variant="inset"] ~ *):is(:where(.peer)[data-state="collapsed"] ~ *) {
      margin-left: calc(var(--spacing) * 2);
    }

    .md\:after\:hidden:after {
      content: var(--tw-content);
      display: none;
    }
  }

  @media (min-width: 64rem) {
    .lg\:block {
      display: block;
    }

    .lg\:flex {
      display: flex;
    }

    .lg\:hidden {
      display: none;
    }

    .lg\:grid-cols-3 {
      grid-template-columns: repeat(3, minmax(0, 1fr));
    }
  }

  @media (min-width: 80rem) {
    .xl\:block {
      display: block;
    }

    .xl\:hidden {
      display: none;
    }
  }

  @media (min-width: 96rem) {
    .\32 xl\:block {
      display: block;
    }

    .\32 xl\:hidden {
      display: none;
    }
  }

  .\[\&_\.recharts-cartesian-axis-tick_text\]\:fill-muted-foreground .recharts-cartesian-axis-tick text {
    fill: var(--color-muted-foreground);
  }

  .\[\&_\.recharts-cartesian-grid_line\[stroke\=\'\#ccc\'\]\]\:stroke-border\/50 .recharts-cartesian-grid line[stroke="#ccc"] {
    stroke: color-mix(in srgb, hsl(var(--border)) 50%, transparent);
  }

  @supports (color: color-mix(in lab, red, red)) {
    .\[\&_\.recharts-cartesian-grid_line\[stroke\=\'\#ccc\'\]\]\:stroke-border\/50 .recharts-cartesian-grid line[stroke="#ccc"] {
      stroke: color-mix(in oklab, var(--color-border) 50%, transparent);
    }
  }

  .\[\&_\.recharts-curve\.recharts-tooltip-cursor\]\:stroke-border .recharts-curve.recharts-tooltip-cursor {
    stroke: var(--color-border);
  }

  .\[\&_\.recharts-dot\[stroke\=\'\#fff\'\]\]\:stroke-transparent .recharts-dot[stroke="#fff"] {
    stroke: #0000;
  }

  .\[\&_\.recharts-layer\]\:outline-hidden .recharts-layer {
    --tw-outline-style: none;
    outline-style: none;
  }

  @media (forced-colors: active) {
    .\[\&_\.recharts-layer\]\:outline-hidden .recharts-layer {
      outline-offset: 2px;
      outline: 2px solid #0000;
    }
  }

  .\[\&_\.recharts-polar-grid_\[stroke\=\'\#ccc\'\]\]\:stroke-border .recharts-polar-grid [stroke="#ccc"] {
    stroke: var(--color-border);
  }

  .\[\&_\.recharts-radial-bar-background-sector\]\:fill-muted .recharts-radial-bar-background-sector, .\[\&_\.recharts-rectangle\.recharts-tooltip-cursor\]\:fill-muted .recharts-rectangle.recharts-tooltip-cursor {
    fill: var(--color-muted);
  }

  .\[\&_\.recharts-reference-line_\[stroke\=\'\#ccc\'\]\]\:stroke-border .recharts-reference-line [stroke="#ccc"] {
    stroke: var(--color-border);
  }

  .\[\&_\.recharts-sector\]\:outline-hidden .recharts-sector {
    --tw-outline-style: none;
    outline-style: none;
  }

  @media (forced-colors: active) {
    .\[\&_\.recharts-sector\]\:outline-hidden .recharts-sector {
      outline-offset: 2px;
      outline: 2px solid #0000;
    }
  }

  .\[\&_\.recharts-sector\[stroke\=\'\#fff\'\]\]\:stroke-transparent .recharts-sector[stroke="#fff"] {
    stroke: #0000;
  }

  .\[\&_\.recharts-surface\]\:outline-hidden .recharts-surface {
    --tw-outline-style: none;
    outline-style: none;
  }

  @media (forced-colors: active) {
    .\[\&_\.recharts-surface\]\:outline-hidden .recharts-surface {
      outline-offset: 2px;
      outline: 2px solid #0000;
    }
  }

  .\[\&_\[cmdk-group-heading\]\]\:px-2 [cmdk-group-heading] {
    padding-inline: calc(var(--spacing) * 2);
  }

  .\[\&_\[cmdk-group-heading\]\]\:py-1\.5 [cmdk-group-heading] {
    padding-block: calc(var(--spacing) * 1.5);
  }

  .\[\&_\[cmdk-group-heading\]\]\:text-xs [cmdk-group-heading] {
    font-size: var(--text-xs);
    line-height: var(--tw-leading, var(--text-xs--line-height));
  }

  .\[\&_\[cmdk-group-heading\]\]\:font-medium [cmdk-group-heading] {
    --tw-font-weight: var(--font-weight-medium);
    font-weight: var(--font-weight-medium);
  }

  .\[\&_\[cmdk-group-heading\]\]\:text-muted-foreground [cmdk-group-heading] {
    color: var(--color-muted-foreground);
  }

  .\[\&_\[cmdk-group\]\]\:px-2 [cmdk-group] {
    padding-inline: calc(var(--spacing) * 2);
  }

  .\[\&_\[cmdk-group\]\:not\(\[hidden\]\)_\~\[cmdk-group\]\]\:pt-0 [cmdk-group]:not([hidden]) ~ [cmdk-group] {
    padding-top: calc(var(--spacing) * 0);
  }

  .\[\&_\[cmdk-input-wrapper\]_svg\]\:h-5 [cmdk-input-wrapper] svg {
    height: calc(var(--spacing) * 5);
  }

  .\[\&_\[cmdk-input-wrapper\]_svg\]\:w-5 [cmdk-input-wrapper] svg {
    width: calc(var(--spacing) * 5);
  }

  .\[\&_\[cmdk-input\]\]\:h-12 [cmdk-input] {
    height: calc(var(--spacing) * 12);
  }

  .\[\&_\[cmdk-item\]\]\:px-2 [cmdk-item] {
    padding-inline: calc(var(--spacing) * 2);
  }

  .\[\&_\[cmdk-item\]\]\:py-3 [cmdk-item] {
    padding-block: calc(var(--spacing) * 3);
  }

  .\[\&_\[cmdk-item\]_svg\]\:h-5 [cmdk-item] svg {
    height: calc(var(--spacing) * 5);
  }

  .\[\&_\[cmdk-item\]_svg\]\:w-5 [cmdk-item] svg {
    width: calc(var(--spacing) * 5);
  }

  .\[\&_svg\]\:pointer-events-none svg {
    pointer-events: none;
  }

  .\[\&_svg\]\:size-4 svg {
    width: calc(var(--spacing) * 4);
    height: calc(var(--spacing) * 4);
  }

  .\[\&_svg\]\:shrink-0 svg {
    flex-shrink: 0;
  }

  .\[\&_tr\]\:border-b tr {
    border-bottom-style: var(--tw-border-style);
    border-bottom-width: 1px;
  }

  .\[\&_tr\:last-child\]\:border-0 tr:last-child {
    border-style: var(--tw-border-style);
    border-width: 0;
  }

  .\[\&\:has\(\>\.day-range-end\)\]\:rounded-r-md:has( > .day-range-end) {
    border-top-right-radius: var(--radius-md);
    border-bottom-right-radius: var(--radius-md);
  }

  .\[\&\:has\(\>\.day-range-start\)\]\:rounded-l-md:has( > .day-range-start) {
    border-top-left-radius: var(--radius-md);
    border-bottom-left-radius: var(--radius-md);
  }

  .\[\&\:has\(\[aria-selected\]\)\]\:rounded-md:has([aria-selected]) {
    border-radius: var(--radius-md);
  }

  .\[\&\:has\(\[aria-selected\]\)\]\:bg-accent:has([aria-selected]) {
    background-color: var(--color-accent);
  }

  .first\:\[\&\:has\(\[aria-selected\]\)\]\:rounded-l-md:first-child:has([aria-selected]) {
    border-top-left-radius: var(--radius-md);
    border-bottom-left-radius: var(--radius-md);
  }

  .last\:\[\&\:has\(\[aria-selected\]\)\]\:rounded-r-md:last-child:has([aria-selected]) {
    border-top-right-radius: var(--radius-md);
    border-bottom-right-radius: var(--radius-md);
  }

  .\[\&\:has\(\[aria-selected\]\.day-range-end\)\]\:rounded-r-md:has([aria-selected].day-range-end) {
    border-top-right-radius: var(--radius-md);
    border-bottom-right-radius: var(--radius-md);
  }

  .\[\&\:has\(\[role\=checkbox\]\)\]\:pr-0:has([role="checkbox"]) {
    padding-right: calc(var(--spacing) * 0);
  }

  .\[\&\>button\]\:hidden > button {
    display: none;
  }

  .\[\&\>span\]\:line-clamp-1 > span {
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    display: -webkit-box;
    overflow: hidden;
  }

  .\[\&\>span\:last-child\]\:truncate > span:last-child {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }

  .\[\&\>svg\]\:size-4 > svg {
    width: calc(var(--spacing) * 4);
    height: calc(var(--spacing) * 4);
  }

  .\[\&\>svg\]\:h-2\.5 > svg {
    height: calc(var(--spacing) * 2.5);
  }

  .\[\&\>svg\]\:h-3 > svg {
    height: calc(var(--spacing) * 3);
  }

  .\[\&\>svg\]\:h-3\.5 > svg {
    height: calc(var(--spacing) * 3.5);
  }

  .\[\&\>svg\]\:w-2\.5 > svg {
    width: calc(var(--spacing) * 2.5);
  }

  .\[\&\>svg\]\:w-3 > svg {
    width: calc(var(--spacing) * 3);
  }

  .\[\&\>svg\]\:w-3\.5 > svg {
    width: calc(var(--spacing) * 3.5);
  }

  .\[\&\>svg\]\:shrink-0 > svg {
    flex-shrink: 0;
  }

  .\[\&\>svg\]\:text-muted-foreground > svg {
    color: var(--color-muted-foreground);
  }

  .\[\&\>svg\]\:text-sidebar-accent-foreground > svg {
    color: var(--color-sidebar-accent-foreground);
  }

  .last\:\[\&\>tr\]\:border-b-0:last-child > tr {
    border-bottom-style: var(--tw-border-style);
    border-bottom-width: 0;
  }

  [data-side="left"][data-collapsible="offcanvas"] .\[\[data-side\=left\]\[data-collapsible\=offcanvas\]_\&\]\:-right-2 {
    right: calc(var(--spacing) * -2);
  }

  [data-side="left"][data-state="collapsed"] .\[\[data-side\=left\]\[data-state\=collapsed\]_\&\]\:cursor-e-resize {
    cursor: e-resize;
  }

  [data-side="right"][data-collapsible="offcanvas"] .\[\[data-side\=right\]\[data-collapsible\=offcanvas\]_\&\]\:-left-2 {
    left: calc(var(--spacing) * -2);
  }

  [data-side="right"][data-state="collapsed"] .\[\[data-side\=right\]\[data-state\=collapsed\]_\&\]\:cursor-w-resize {
    cursor: w-resize;
  }
}

@supports (((-webkit-hyphens: none)) and (not (margin-trim: inline))) or ((-moz-orient: inline) and (not (color: rgb(from red r g b)))) {
  @layer base {
    *, :before, :after {
      --tw-translate-x: 0;
      --tw-translate-y: 0;
      --tw-translate-z: 0;
      --tw-space-y-reverse: 0;
      --tw-space-x-reverse: 0;
      --tw-border-style: solid;
      --tw-leading: initial;
      --tw-font-weight: initial;
      --tw-tracking: initial;
      --tw-ordinal: initial;
      --tw-slashed-zero: initial;
      --tw-numeric-figure: initial;
      --tw-numeric-spacing: initial;
      --tw-numeric-fraction: initial;
      --tw-shadow: 0 0 #0000;
      --tw-shadow-color: initial;
      --tw-shadow-alpha: 100%;
      --tw-inset-shadow: 0 0 #0000;
      --tw-inset-shadow-color: initial;
      --tw-inset-shadow-alpha: 100%;
      --tw-ring-color: initial;
      --tw-ring-shadow: 0 0 #0000;
      --tw-inset-ring-color: initial;
      --tw-inset-ring-shadow: 0 0 #0000;
      --tw-ring-inset: initial;
      --tw-ring-offset-width: 0px;
      --tw-ring-offset-color: #fff;
      --tw-ring-offset-shadow: 0 0 #0000;
      --tw-outline-style: solid;
      --tw-blur: initial;
      --tw-brightness: initial;
      --tw-contrast: initial;
      --tw-grayscale: initial;
      --tw-hue-rotate: initial;
      --tw-invert: initial;
      --tw-opacity: initial;
      --tw-saturate: initial;
      --tw-sepia: initial;
      --tw-drop-shadow: initial;
      --tw-drop-shadow-color: initial;
      --tw-drop-shadow-alpha: 100%;
      --tw-drop-shadow-size: initial;
      --tw-duration: initial;
      --tw-ease: initial;
      --tw-content: "";
    }

    ::backdrop {
      --tw-translate-x: 0;
      --tw-translate-y: 0;
      --tw-translate-z: 0;
      --tw-space-y-reverse: 0;
      --tw-space-x-reverse: 0;
      --tw-border-style: solid;
      --tw-leading: initial;
      --tw-font-weight: initial;
      --tw-tracking: initial;
      --tw-ordinal: initial;
      --tw-slashed-zero: initial;
      --tw-numeric-figure: initial;
      --tw-numeric-spacing: initial;
      --tw-numeric-fraction: initial;
      --tw-shadow: 0 0 #0000;
      --tw-shadow-color: initial;
      --tw-shadow-alpha: 100%;
      --tw-inset-shadow: 0 0 #0000;
      --tw-inset-shadow-color: initial;
      --tw-inset-shadow-alpha: 100%;
      --tw-ring-color: initial;
      --tw-ring-shadow: 0 0 #0000;
      --tw-inset-ring-color: initial;
      --tw-inset-ring-shadow: 0 0 #0000;
      --tw-ring-inset: initial;
      --tw-ring-offset-width: 0px;
      --tw-ring-offset-color: #fff;
      --tw-ring-offset-shadow: 0 0 #0000;
      --tw-outline-style: solid;
      --tw-blur: initial;
      --tw-brightness: initial;
      --tw-contrast: initial;
      --tw-grayscale: initial;
      --tw-hue-rotate: initial;
      --tw-invert: initial;
      --tw-opacity: initial;
      --tw-saturate: initial;
      --tw-sepia: initial;
      --tw-drop-shadow: initial;
      --tw-drop-shadow-color: initial;
      --tw-drop-shadow-alpha: 100%;
      --tw-drop-shadow-size: initial;
      --tw-duration: initial;
      --tw-ease: initial;
      --tw-content: "";
    }
  }
}

@property --tw-translate-x {
  syntax: "*";
  inherits: false;
  initial-value: 0;
}

@property --tw-translate-y {
  syntax: "*";
  inherits: false;
  initial-value: 0;
}

@property --tw-translate-z {
  syntax: "*";
  inherits: false;
  initial-value: 0;
}

@property --tw-space-y-reverse {
  syntax: "*";
  inherits: false;
  initial-value: 0;
}

@property --tw-space-x-reverse {
  syntax: "*";
  inherits: false;
  initial-value: 0;
}

@property --tw-border-style {
  syntax: "*";
  inherits: false;
  initial-value: solid;
}

@property --tw-leading {
  syntax: "*";
  inherits: false
}

@property --tw-font-weight {
  syntax: "*";
  inherits: false
}

@property --tw-tracking {
  syntax: "*";
  inherits: false
}

@property --tw-ordinal {
  syntax: "*";
  inherits: false
}

@property --tw-slashed-zero {
  syntax: "*";
  inherits: false
}

@property --tw-numeric-figure {
  syntax: "*";
  inherits: false
}

@property --tw-numeric-spacing {
  syntax: "*";
  inherits: false
}

@property --tw-numeric-fraction {
  syntax: "*";
  inherits: false
}

@property --tw-shadow {
  syntax: "*";
  inherits: false;
  initial-value: 0 0 #0000;
}

@property --tw-shadow-color {
  syntax: "*";
  inherits: false
}

@property --tw-shadow-alpha {
  syntax: "<percentage>";
  inherits: false;
  initial-value: 100%;
}

@property --tw-inset-shadow {
  syntax: "*";
  inherits: false;
  initial-value: 0 0 #0000;
}

@property --tw-inset-shadow-color {
  syntax: "*";
  inherits: false
}

@property --tw-inset-shadow-alpha {
  syntax: "<percentage>";
  inherits: false;
  initial-value: 100%;
}

@property --tw-ring-color {
  syntax: "*";
  inherits: false
}

@property --tw-ring-shadow {
  syntax: "*";
  inherits: false;
  initial-value: 0 0 #0000;
}

@property --tw-inset-ring-color {
  syntax: "*";
  inherits: false
}

@property --tw-inset-ring-shadow {
  syntax: "*";
  inherits: false;
  initial-value: 0 0 #0000;
}

@property --tw-ring-inset {
  syntax: "*";
  inherits: false
}

@property --tw-ring-offset-width {
  syntax: "<length>";
  inherits: false;
  initial-value: 0;
}

@property --tw-ring-offset-color {
  syntax: "*";
  inherits: false;
  initial-value: #fff;
}

@property --tw-ring-offset-shadow {
  syntax: "*";
  inherits: false;
  initial-value: 0 0 #0000;
}

@property --tw-outline-style {
  syntax: "*";
  inherits: false;
  initial-value: solid;
}

@property --tw-blur {
  syntax: "*";
  inherits: false
}

@property --tw-brightness {
  syntax: "*";
  inherits: false
}

@property --tw-contrast {
  syntax: "*";
  inherits: false
}

@property --tw-grayscale {
  syntax: "*";
  inherits: false
}

@property --tw-hue-rotate {
  syntax: "*";
  inherits: false
}

@property --tw-invert {
  syntax: "*";
  inherits: false
}

@property --tw-opacity {
  syntax: "*";
  inherits: false
}

@property --tw-saturate {
  syntax: "*";
  inherits: false
}

@property --tw-sepia {
  syntax: "*";
  inherits: false
}

@property --tw-drop-shadow {
  syntax: "*";
  inherits: false
}

@property --tw-drop-shadow-color {
  syntax: "*";
  inherits: false
}

@property --tw-drop-shadow-alpha {
  syntax: "<percentage>";
  inherits: false;
  initial-value: 100%;
}

@property --tw-drop-shadow-size {
  syntax: "*";
  inherits: false
}

@property --tw-duration {
  syntax: "*";
  inherits: false
}

@property --tw-ease {
  syntax: "*";
  inherits: false
}

@property --tw-content {
  syntax: "*";
  inherits: false;
  initial-value: "";
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}

@keyframes pulse {
  50% {
    opacity: .5;
  }
}

@keyframes caret-blink {
  0%, 70%, to {
    opacity: 1;
  }

  20%, 50% {
    opacity: 0;
  }
}
